<template>
  <div>
    <load-profile />
    <!-- Table Container Card -->
    <b-card>
      <validation-observer
        ref="editForm"
        #default="{ invalid }"
      >
        <!-- form -->
        <n-form-confirmation
          key="editForm"
          :form="$refs.editForm"
          :disabled="invalid"
          @submit="submit"
        >
          <n-input
            v-model="data"
            :fields="fields"
            :init-value="initData"
          >
            <template #parentId="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-async-single-select
                    v-model="data[item.field.key]"
                    :name="item.field.key"
                    :repository="item.field.repository"
                    :selection-key="item.field.selectionKey"
                    :selection-label="item.field.selectionLabel"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                    :readonly="true"
                    @optionChange="changeOption"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #loginTimeout="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="timeout"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #accrueDepositPeriod="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="dropCommissionPeriod"
                    clearable
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
            <template #state="item">
              <b-form-group :label-for="item.field.key">
                <validation-provider
                  #default="{ errors }"
                  :vid="item.field.key"
                  :name="$t(item.field.label)"
                  :rules="item.field.rules"
                >
                  <label>
                    {{ $t(item.field.label) }}
                  </label>
                  <n-single-select
                    v-model="data[item.field.key]"
                    :reduce="true"
                    :options="an"
                    :clearable="false"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </template>
          </n-input>
          <b-row>
            <b-col
              cols="12"
              class="text-right"
            >
              <n-button-loading
                v-if="$can('update', 'player')"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
              >
                {{ $t("button.saveChanges") }}
              </n-button-loading>
              <n-button-loading
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t("button.back") }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardText, BCol, BForm, BFormGroup, BFormInput, BFormRadioGroup, BLink, BRow, } from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading.vue'
import NFormConfirmation from '@/components/NFormConfirmation.vue'
import NInput from '@/components/NInput.vue'
import Alphabet from '@/data/numberAlphabet'
import Timeout from '@/data/timeout'
import DropCommissionPeriod from '@/data/dropCommissionPeriod'
import NAsyncSingleSelect from '@/components/NAsyncSingleSelect.vue'
import NSingleSelect from '@/components/NSingleSelect.vue'
import AN from '@/data/an'
import FormInputAN from './formInputAN'
import VendorFormInput from './vendorFormInput'
import FormInput from './formInput'

const PlayerRepository = Repository.get('player')

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    NAsyncSingleSelect,
    NSingleSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [],
      data: {
        firstName: null,
        lastName: null,
        image: null,
        gender: 'male',
        username: null,
        email: null,
        password: null,
      },
      initData: {},
      loading: false,
      checkUsernameLoading: false,
      usernameValid: false,
      getSuggestionUsernameLoading: false,
      username: {
        parent: null,
        first: null,
        second: null,
        third: null,
        fourth: null,
      },
      an: AN,
    }
  },
  computed: {
    userData() {
      return this.$store.state.profile.name
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem('userData'))
    },
    // fields() {
    //   if (this.initData.isVendor) {
    //     this.fields = VendorFormInput;
    //   }

    //   if (!this.userData.searchState) {
    //     return FormInputAN.map((value) => {
    //       if (value.key === "password" || value.key === "retypePassword") {
    //         value.rules = value.rules.replace("required|", "");
    //         value.rules = value.rules.replace("required", "");
    //         value.rules = value.rules.replace("", "required|");
    //       } else if (value.key === "username") {
    //         value.disabled = false;
    //       }

    //       return value;
    //     });
    //   }

    //   return FormInput.map((value) => {
    //     if (value.key === "password" || value.key === "retypePassword") {
    //       value.rules = value.rules.replace("required|", "");
    //       value.rules = value.rules.replace("required", "");
    //       value.rules = value.rules.replace("", "required|");
    //     } else if (value.key === "username") {
    //       value.disabled = false;
    //     }

    //     return value;
    //   });
    // },
  },
  mounted() {
    this.setInputField()
    this.show()
  },
  methods: {
    setInputField() {
      if (this.initData.isVendor) {
        this.fields = VendorFormInput
      } else if (this.userData.searchState) {
        this.fields = FormInputAN.map(value => {
          if (value.key === 'password' || value.key === 'retypePassword') {
            value.rules = value.rules.replace('required|', '')
            value.rules = value.rules.replace('required', '')
            // value.rules = value.rules.replace("", "required|");
          } else if (value.key === 'username') {
            value.disabled = false
          }

          return value
        })
      } else {
        this.fields = FormInput.map(value => {
          if (value.key === 'password' || value.key === 'retypePassword') {
            value.rules = value.rules.replace('required|', '')
            value.rules = value.rules.replace('required', '')
            // value.rules = value.rules.replace("", "required|");
          } else if (value.key === 'username') {
            value.disabled = false
          }

          return value
        })
      }
    },
    changeOption(value) {
      this.username.parent = value?.username
    },
    show() {
      PlayerRepository.show(this.$route.params.id).then(response => {
        const { data } = response.data
        this.initData = {
          ...data,
          username: data.username.replace(data.parentUsername, ''),
        }
        this.setInputField()
      })
    },
    submit() {
      this.$refs.editForm.validate().then(success => {
        if (success) {
          this.loading = true
          PlayerRepository.update(this.$route.params.id, {
            ...this.data,
            username: `${this.data.parentUsername}${this.data.username}`,
          })
            .then(response => {
              this.$router.push({
                name: 'view-player',
                params: { id: response.data.data.id },
              })
            })
            .catch(error => {
              if (error.response?.status === 422) {
                this.$refs.editForm.setErrors(error.response?.data?.errors)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    concatUsername() {
      if (
        this.username.first
        && this.username.second
        && this.username.third
        && this.username.fourth
        && this.username.parent
      ) {
        this.data.username = `${this.username.parent}${this.username.first}${this.username.second}${this.username.third}${this.username.fourth}`
      }
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const alphabet = [...Alphabet]
    const timeout = [...Timeout]
    const dropCommissionPeriod = [...DropCommissionPeriod]
    return { alphabet, timeout, dropCommissionPeriod }
  },
}
</script>
