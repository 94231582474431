export default [
  {
    id: "A",
    name: "A",
  },
  {
    id: "N",
    name: "N",
  },
];
